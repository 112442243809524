<template>
  <v-card>
    <v-card-title class="my__v-card-title primary">
      <div class="white--text">
        <div>
          {{ surveyTypeName(1) }}
        </div>
        <div class="text-caption"
             style="margin-top: -6px;">
          {{ $store.state.appbarText.subHeader }}
        </div>
      </div>
      <v-icon class="white--text"
              @click="close">
        mdi-close
      </v-icon>
    </v-card-title>
    <v-card-text class="my__v-card-text">
      <div class="primary-light pa-1" style="width: 100%; height: fit-content; overflow-y: hidden;">
        <v-select v-model="bridgePartId" :items="bridgePartOptions"
                  item-value="bridgePartId" item-text="name"
                  dense flat solo hide-details class="mx-auto" background-color="primary-light"
                  style="width: 75px;"/>
      </div>
      <v-tabs v-model="tab"
              grow
              show-arrows>
        <v-tabs-slider color="primary"/>
        <v-tab>
          ภาพถ่ายโดยรวม
        </v-tab>
        <v-tab>
          ภาพถ่ายละเอียด
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item>
          <div class="pa-3 pt-0 fill-height">
            <div class="d-flex justify-space-between align-center mt-2">
              <span class="primary--text font-weight-bold">
                จำนวนภาพถ่าย 0/9
              </span>
              <v-btn class="primary--text white ml-2"
                     elevation="2"
                     icon
                     @click="$store.dispatch('inspectionDamageDialog/fetchRoutineDialog')">
                <v-icon>
                  mdi-refresh
                  {{ (loading) ? 'mdi-spin-fast' : '' }}
                </v-icon>
              </v-btn>
            </div>
            <BridgeOverview
              v-if="tab===0 && mountSvg"
              :accidentMode="true"
              :brFlag="brFlag"
              :bridgeDirection="bridgeDirection"
              :bridgePart="bridgePartComponent"
              :bridgeType="bridgeType"
              :bridgeView="bridgeView"
              :endLocation="endLocation"
              :bridgeSelected="getBridge[0]"
              :imageDirection="$vuetify.breakpoint.smAndUp ? 'horizontal' : 'vertical'"
              :routineDamageImage="routineOverviewItem"
              :startLocation="startLocation"
              accidentType="overview"
              class="bridgeSvgOverview mt-2"
              disableDirection
              style="width: 100%; border: 1px solid #c22d47; border-radius: 5px;"
              @clicked="setFormParts"/>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="pa-3 fill-height">
            <v-select v-model="bridgeView"
                      :items="bridgeViewArray"
                      dense
                      hide-details
                      item-text="name_th"
                      item-value="name_eng"
                      outlined>
              <template v-slot:selection="{ item }">
                <span class="d-flex justify-center"
                      style="width: 100%;">
                  {{ item }}
                </span>
              </template>
            </v-select>
            <div class="d-flex justify-space-between align-center mt-2">
              <span class="primary--text font-weight-bold">
                จำนวนภาพถ่าย 0/9
              </span>
              <v-btn class="primary--text white ml-2"
                     elevation="2"
                     icon
                     @click="$store.dispatch('inspectionDamageDialog/fetchRoutineDialog')">
                <v-icon>
                  mdi-refresh
                  {{ (loading) ? 'mdi-spin-fast' : '' }}
                </v-icon>
              </v-btn>
            </div>
            <BridgeOverview
              v-if="tab===1 && mountSvg"
              :accidentMode="true"
              :brFlag="brFlag"
              :bridgeDirection="bridgeDirection"
              :bridgePart="bridgePartComponent"
              :bridgeType="bridgeType"
              :bridgeView="bridgeView"
              :endLocation="endLocation"
              :bridgeSelected="getBridge[0]"
              :imageDirection="$vuetify.breakpoint.smAndUp ? 'horizontal' : 'vertical'"
              :routineDamageImage="routineDetailItem"
              :startLocation="startLocation"
              accidentType="detail"
              class="bridgeSvgFine mt-2"
              disableDirection
              style="width: 100%; border: 1px solid #c22d47;"
              @clicked="setFormParts"/>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-card-actions class="pa-4">
      <v-btn v-if="can_add_inspection && canEdit_permission"
             @click="$store.dispatch('inspectionDamageDialog/openEditInspection')">
        <v-icon>mdi-pencil</v-icon>
        <span class="ml-2">แก้ไข</span>
      </v-btn>
      <v-btn v-if="can_finish_inspection"
             class="ml-auto"
             color="success darken-1 white--text"
             elevation="0"
             @click="doneInspection">
        <v-icon>mdi-clipboard-check</v-icon>
        <span class="ml-2">เสร็จสิ้นการสำรวจ</span>
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="apiSetted"
              max-width="250"
              persistent>
      <v-card>
        <v-card-text class="my__v-card-text">
          <div v-if="isSuccess === true"
               class="pa-4 pb-1 text-center">
            <v-icon class="green--text"
                    style="font-size: 57px;">
              mdi-check-circle-outline
            </v-icon>
            <div class="black--text font-weight-bold my-4"
                 style="font-size: 1.5rem;">
              บันทึกข้อมูลสำเร็จ
            </div>
            <div class="progress-green">
              <div class="color-green"></div>
            </div>
          </div>
          <div v-if="isSuccess === false"
               class="pa-4 pb-1 text-center">
            <v-icon class="green--text"
                    style="font-size: 57px;">
              mdi-check-circle-outline
            </v-icon>
            <div class="black--text font-weight-bold my-4"
                 style="font-size: 1.5rem;">
              บันทึกข้อมูลไม่สำเร็จ
            </div>
            <div class="progress-red">
              <div class="color-red"></div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BridgeOverview from '@/components/bridge/bridgeOverview'

export default {
  name: 'NormalForm',
  components: { BridgeOverview },
  beforeMount () {
    // if no default bridgePartId -> use L2/R2
    if (!this.bridgePartId) {
      const bridgePartId = this.bridgePartArray[1] ? this.bridgePartArray[1].bridgePartId : this.bridgePartArray[0].bridgePartId
      this.$store.commit('inspectionDamageDialog/SET_SELECTED_BRIDGE_PART', bridgePartId)
    }
    this.changeBridgeEvent()
  },
  mounted () {
    this.tab = 0
    this.$store.commit('inspectionDamageDialog/SET_ROUTINE_MODE', 'overview')
  },
  data: () => ({
    tab: 0,
    apiSetted: false,
    isSuccess: null,
    // --
    bridgeView: '',
    bridgeViewArray: [],
    imageDirection: '',
    brFlag: 1,
    startLocation: '',
    endLocation: '',
    bridgeDirection: '',
    mountSvg: true,
    // bridgeOverview
    bridgePartComponent: [],
    bridgeType: null
  }),
  computed: {
    ...mapGetters('damageDetailSupply', { surveyTypeName: 'surveyTypeName' }),
    ...mapGetters('inspectionDamageDialog', { getBridge: 'getBridge', bridgePartArray: 'bridgePartArray', bridgePartOptions: 'bridgePartOptions' }),
    ...mapGetters('bridgeDetail', { bridgeHasA1: 'bridgeHasA1', bridgeHasA2: 'bridgeHasA2' }),
    ...mapGetters('bridgeDetail', { canEdit_permission: 'canEdit_permission' }),
    ...mapState('inspectionDamageDialog', { routineOverviewItem: 'routineOverviewItem', routineTopItem: 'routineTopItem', routineBottomItem: 'routineBottomItem' }),
    ...mapState('inspectionDamageDialog', { loading: 'loading', bridgeId: 'bridgeId' }),
    ...mapState('bridgeDetail', { bridgeOverview: 'bridgeOverview' }),
    ...mapGetters({ can_add_inspection: 'can_add_inspection', can_finish_inspection: 'can_finish_inspection' }),
    routineDetailItem () {
      return (this.bridgeView === 'topView') ? this.routineTopItem : this.routineBottomItem
    },
    drawer: {
      get () {
        return this.$store.state.inspectionDamageDialog.drawer
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_DRAWER', to)
      }
    },
    bridgePartId: {
      get () {
        return this.$store.state.inspectionDamageDialog.bridgePartId
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_SELECTED_BRIDGE_PART', to)
      }
    }
  },
  methods: {
    close () {
      this.$store.commit('inspectionDamageDialog/SET_DRAWER', 0)
      this.$store.dispatch('bridgeDetail/getInspectionList', { id: this.bridgeId, force: true })
    },
    setFormParts (parts) {
      if (!parts.seq) return
      let currentMode = ''
      if (this.tab === 0) {
        currentMode = 'overview'
      } else if (this.tab === 1 && this.bridgeView === 'topView') {
        currentMode = 'top'
      } else {
        currentMode = 'bottom'
      }
      this.$store.commit('inspectionDamageDialog/SET_ROUTINE_MODE', currentMode)
      this.$store.commit('inspectionDamageDialog/SET_OVERVIEW_ROUTINE_PARTS', parts)
      const payload = {
        file: parts.currentImage || null,
        callback: this.setImage,
        header: 'ภาพถ่ายจุดที่ ' + (parts.seq || 'seq'),
        subHeader: parts.seqDetail || 'seqDetail',
        showRemove: true,
        drawer: true,
        removeCallback: this.removeImage,
        caption: '',
        showResponseDialog: true
      }
      this.$store.commit('imageUploader/MUTATE_COMPONENT', payload)
    },
    async removeImage () {
      return await this.$store.dispatch('inspectionDamageDialog/removeRoutineImage')
    },
    async setImage (image) {
      this.$store.commit('inspectionDamageDialog/SET_OVERVIEW_ROUTINE_IMAGE', image)
      return await this.$store.dispatch('inspectionDamageDialog/setRoutineImage')
    },
    changeBridgeEvent () {
      this.mountSvg = false
      this.description = ''
      this.startLocation = this.bridgeOverview.startLocation
      this.endLocation = this.bridgeOverview.endLocation
      if (this.bridgeOverview.direction === 'ซ้ายทาง') {
        this.bridgeDirection = 'left'
      } else if (this.bridgeOverview.direction === 'ขวาทาง') {
        this.bridgeDirection = 'right'
      }
      this.bridgeType = this.getBridge[0].type_name
      this.brFlag = 1
      // Routine does not have side type
      this.imageDirection = 'horizontal'
      this.bridgeViewArray = ['topView', 'bottomView']
      if (this.bridgeView === 'sideView' || !this.bridgeView) {
        this.bridgeView = 'topView'
      } else if (this.bridgeView === 'crossSectionView') {
        this.bridgeView = 'bottomView'
      }
      // --
      const bridgePartComponent = []
      const bridgeSegment = this.getBridge.bridge_segment
      if (this.bridgeHasA1) {
        bridgePartComponent.push({ segment: 'A', seq: 1 })
      }
      bridgeSegment.forEach((item, index) => {
        bridgePartComponent.push({ segment: 'S', seq: item.seq || index + 1 })
      })
      if (this.bridgeHasA2) {
        bridgePartComponent.push({ segment: 'A', seq: 2 })
      }
      this.bridgePartComponent = bridgePartComponent
      this.svgStep = 0
      this.$nextTick(() => {
        this.mountSvg = true
      })
    },
    async doneInspection () {
      this.isSuccess = await this.$store.dispatch('inspectionDamageDialog/setInspectionDone')
      this.apiSetted = true
      setTimeout(() => {
        this.$store.dispatch('bridgeDetail/getInspectionList', { id: this.bridgeId, force: true })
        this.$store.commit('inspectionDamageDialog/SET_DRAWER', false)
      }, 3000)
    }
  },
  watch: {
    drawer: {
      immediate: true,
      handler (to, from) {
        if (to) {
          this.tab = 0
          setTimeout(this.changeBridgeEvent, 100)
        }
      }
    },
    tab () {
      this.bridgeView = 'topView'
      setTimeout(this.changeBridgeEvent, 100)
    },
    bridgeView (to) {
      this.mountSvg = false
      this.$nextTick(() => {
        this.mountSvg = true
        this.changeBridgeEvent()
      })
    },
    routineDetailItem: {
      deep: true,
      handler () {
        this.$nextTick(() => {
          this.changeBridgeEvent()
        })
      }
    },
    routineOverviewItem: {
      deep: true,
      handler () {
        this.$nextTick(() => {
          this.changeBridgeEvent()
        })
      }
    },
    bridgePartId () {
      this.mountSvg = false
      this.$nextTick(() => {
        this.mountSvg = true
        this.$store.dispatch('inspectionDamageDialog/fetchRoutineDialog')
        this.changeBridgeEvent()
      })
    }
  }
}
</script>

<style scoped>
::v-deep .v-tabs-items {
  height: calc(100% - 94px);
}

::v-deep .v-window__container, ::v-deep .v-window-item {
  height: 100%;
}

.bridgeSvgOverview {
  height: calc(100% - 45px) !important;
}

.bridgeSvgFine {
  height: calc(100% - 84px) !important;
}
</style>
